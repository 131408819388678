import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  LinkOutlined,
  BackwardOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs';

import config from "../../utils/config";
import Delete from "../../components/Papers/Delete";
import Update from "../../components/Papers/Update";
import Create from "../../components/Papers/Create";
import endpoints from "../../utils/endpoints";
import "./Papers.css";

const SpinIcon = (
  <LoadingOutlined
    style={{
      fontSize: 16,
      marginRight: '10px',
      color: '#009195'
    }}
    spin
  />
);

const columns = [
  {
    title: "CATEGORY",
    dataIndex: "category",
    key: "category",
    width: "2%",
    filters: [
      {
        text: 'General Cardiology',
        value: 1,
      },
      {
        text: 'Heart Failure',
        value: 2,
      },
      {
        text: 'Events',
        value: 3,
      },
    ],
    onFilter: (value, record) => record.category_id === value,
  },
  {
    title: "IMAGE",
    dataIndex: "image",
    key: "image",
    width: "18%",
  },
  {
    title: "TITLE",
    dataIndex: "title",
    key: "title",
    width: "20%",
  },
  {
    title: "SUBTITLE",
    dataIndex: "subtitle",
    key: "subtitle",
    width: "20%",
  },
  {
    title: "DATE",
    key: "date",
    dataIndex: "date",
    width: "10%",
  },
  {
    title: "MONTH",
    key: "month",
    dataIndex: "month",
    width: "10%",
  },
  {
    title: "WEEK",
    key: "week",
    dataIndex: "week",
    width: "5%",
  },
  {
    title: "POS",
    key: "position",
    dataIndex: "position",
    width: "5%",
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
    width: "5%",
  },
  {
    title: "AUTHOR",
    key: "author",
    dataIndex: "author",
    width: "10%",
  },
  {
    title: "LINK",
    key: "link",
    dataIndex: "link",
    width: "5%",
  },
  {
    title: "",
    key: "actions",
    dataIndex: "actions",
  },
];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Papers() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);

  useEffect(() => {
    handleGetPapers();
  }, [])

  function handleGetPapers() {
    setIsLoading(true);
    axios.get(endpoints.papers.getPapersBackoffice
    ).then((response) => {
      console.log(response.data);
      let papersData = [];
      let papersDataServer = response.data[0];
      let categoriesDataServer = response.data[1];
      let typesDataServer = response.data[2];
      let eventsDataServer = response.data[3];
      for (let i = 0; i < papersDataServer.length; i++) {
        var categoryArray = categoriesDataServer.filter(function (el) {
          return el.id === papersDataServer[i].category_id;
        });

        let arrayData = papersDataServer[i];
        arrayData.date = dayjs(arrayData.date).format('YYYY-MM-DD HH:mm:ss')
        let statusString = handleCalcStatus(arrayData.date);

        let aux = {
          key: i,
          category_id: arrayData.category_id,
          category: <div className="img-paper-category"><img src={`${config.server_ip}/images/categories/${categoryArray[0].img}`} /></div>,
          image: <div className="img-paper"><img src={`${config.server_ip}/images/papers/${arrayData.img}`} /></div>,
          title: <div className="title-paper">{arrayData.title}</div>,
          subtitle: <div className="description-paper">{arrayData.description}</div>,
          date: arrayData.date,
          month: arrayData.month.charAt(0).toUpperCase() + arrayData.month.slice(1),
          week: arrayData.week,
          position: arrayData.position,
          status: <div className={`status-table ${statusString}`}>{statusString}</div>,
          author: arrayData.author,
          link: <Button onClick={() => window.open(arrayData.link, "_blank")}><LinkOutlined style={{ fontSize: '16px' }} /></Button>,
          actions:
            <div className="actions-buttons-div">
              <Button type="primary" className="edit-action" onClick={() => handleOpenUpdate(arrayData)}>
                <EditOutlined style={{ fontSize: '16px' }} />
              </Button>
              <Button danger className="delete-action" onClick={() => handleOpenDelete(arrayData)}>
                <DeleteOutlined style={{ fontSize: '16px' }} />
              </Button>
            </div>
        }

        papersData.push(aux);
      }

      setData(papersData);
      setCategories(categoriesDataServer);
      setTypes(typesDataServer);
      setEvents(eventsDataServer);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  function handleCalcStatus(date) {
    if (date) {
      let today = dayjs();
      let seconds = today.diff(date, 'seconds')
      return seconds > 0 ? 'Published' : 'Scheduled';
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
    handleGetPapers();
  }

  function handleCloseCreate() {
    setSelectedData(data);
    setIsOpenCreate(false);
    handleGetPapers();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
    handleGetPapers();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    handleGetPapers();
  }


  return (
    <div className="tabled">
      <Create open={isOpenCreate} close={handleCloseCreate} categories={categories} types={types} events={events} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} categories={categories} types={types} events={events} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Papers Table"
            extra={[
              <Button className="mr-10" size="large" onClick={handleGetPapers}><ReloadOutlined /></Button>,
              <Button size="large" type="primary" onClick={handleOpenCreate}>Create Paper</Button>
            ]}
          >
            <div className="table-responsive">
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={data}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
