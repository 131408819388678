import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  LinkOutlined,
  BackwardOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs';

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";
import "./Users.css";
import DrawerExportTable from "../../components/Export/DrawerExportTable";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Country",
    key: "country",
    dataIndex: "country",
    width: "5%",
  },
  {
    title: "",
    key: "actions",
    dataIndex: "actions",
  },
];

export default function Users() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenExport, setIsOpenExport] = useState(false);

  useEffect(() => {
    handleGetPapers();
  }, [])

  function handleGetPapers() {
    setIsLoading(true);
    axios.get(endpoints.users.getUsers
    ).then((response) => {
      console.log(response.data);
      let usersData = [];
      for (let i = 0; i < response.data.length; i++) {
        let arrayData = response.data[i]
        let aux = {
          key: i,
          id: i,
          name: arrayData.name,
          email: arrayData.email,
          country: arrayData.country,
        }

        usersData.push(aux);
      }

      setData(usersData);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  function handleOpenExport(){
    setIsOpenExport(true);
  }

  function handleCloseExport(){
    setIsOpenExport(false);
  }


  return (
    <div className="tabled">
      <DrawerExportTable open={isOpenExport} close={handleCloseExport} data={data} type={'Utilizadores'} />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Users Table"
            extra={[
              <Button size="large" className="mr-10" onClick={handleGetPapers}><ReloadOutlined /></Button>,
              <Button type="primary" size="large" onClick={handleOpenExport}>Export data</Button>
            ]
            }
          >
            <div className="table-responsive">
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={data}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
