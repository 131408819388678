import { Row, Col, Button, Input, DatePicker, Form, notification, Upload, Radio, Select, Drawer } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";

import config from "../../utils/config";
import localeData from "dayjs/plugin/localeData";
import Preview from "./Preview";
import endpoints from "../../utils/endpoints";

const { Dragger } = Upload;
const { Option } = Select;
dayjs.extend(localeData);

export default function Create({ open, close, categories, types, events }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");
  const [previewPaper, setPreviewPaper] = useState({});
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [months] = useState(dayjs.months());

  const [form] = Form.useForm();

  const uploadProps = {
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      console.log(file);
      handlePreviewFile(file);
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: [],
  };

  async function handlePreviewFile(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file);
      });
    } else {
      values.img = null;
    }

    formData.append("data", JSON.stringify(values));
    fetch(`${config.server_ip}${endpoints.papers.postPaper}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.err) throw resData;
        setTimeout(() => {
          setIsButtonLoading(false);
          form.resetFields();
          close();
        }, 1000);
      })
      .catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleOpenPreviewPaper() {
    setPreviewPaper(form.getFieldsValue());
    setIsOpenPreview(true);
  }

  function handleClosePreviewPaper() {
    setIsOpenPreview(false);
  }

  function normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  function handleClose() {
    close();
    setFileList([]);
    form.resetFields();
    setIsButtonLoading(false);
  }

  return (
    <Drawer
      width={1000}
      title={`Create paper`}
      id="create-paper"
      open={open}
      onClose={handleClose}
      extra={[
        <Button size="large" className="mr-10" disabled={isButtonLoading} onClick={handleOpenPreviewPaper}>
          Preview
        </Button>,
        <Button size="large" className="mr-10" disabled={isButtonLoading} onClick={handleClose}>
          Cancel
        </Button>,
        <Button type="primary" size="large" loading={isButtonLoading} onClick={form.submit}>
          Create
        </Button>,
      ]}
    >
      <Preview open={isOpenPreview} close={handleClosePreviewPaper} data={previewPaper} />
      <Form form={form} id="create-paper-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="category_id" rules={[{ required: true, message: "This field is required." }]}>
              <Radio.Group className="w-100 d-flex jc-sb">
                {categories.map((value, index) => {
                  return (
                    <Radio.Button value={value.id} className="minw-200 minh-100 p-10">
                      <div className="img-radio-button">
                        <img src={`${config.server_ip}/images/categories/${value.img}`} alt="" />
                      </div>
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile}>
          <Dragger {...uploadProps} className={`dragger ${filePreview ? "hasPreview" : ""}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <div
              className={`preview_file sponsor ${filePreview ? "hasPreview" : ""}`}
              style={{
                backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}/images/papers/${filePreview}`})`,
              }}
            ></div>
          </Dragger>
        </Form.Item>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="title" label="Title" rules={[{ required: true, message: "This field is required." }]}>
              <TextArea rows={4} size="large" placeholder="Title here..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: "This field is required." }]}>
              <TextArea rows={4} size="large" placeholder="Description here..." />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.category_id !== currentValues.category_id}>
          {({ getFieldValue }) =>
            getFieldValue("category_id") === 3 ? (
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <div className="input-div">
                    <Form.Item
                      name="event_id"
                      label="Event"
                      rules={[
                        {
                          required: form.getFieldValue("category_id") === 3 ? true : false,
                          message: "This field is required.",
                        },
                      ]}
                    >
                      <Select size="large" placeholder="Type...">
                        {events.map((value, index) => {
                          return <Option value={value.id}>{value.name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ) : null
          }
        </Form.Item>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <div className="input-div">
              <Form.Item name="type_id" label="Type of paper" rules={[{ required: true, message: "This field is required." }]}>
                <Select size="large" placeholder="Type...">
                  {types.map((value, index) => {
                    return <Option value={value.id}>{value.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div className="input-div">
              <Form.Item name="labels" label="Labels">
                <Input size="large" placeholder="Labels..." />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div className="input-div">
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.category_id !== currentValues.category_id}>
                {({ getFieldValue }) => (
                  <Form.Item name="position" label="Position" rules={[{ required: true, message: "This field is required." }]}>
                    <Select size="large" placeholder="Position...">
                      {getFieldValue("category_id") === 3 ? (
                        <>
                          <Option value={1}>1</Option>
                          <Option value={2}>2</Option>
                          <Option value={3}>3</Option>
                          <Option value={4}>4</Option>
                          <Option value={5}>5</Option>
                          <Option value={6}>6</Option>
                          <Option value={7}>7</Option>
                          <Option value={8}>8</Option>
                          <Option value={9}>9</Option>
                          <Option value={10}>10</Option>
                        </>
                      ) : (
                        <>
                          <Option value={1}>1</Option>
                          <Option value={2}>2</Option>
                          <Option value={3}>3</Option>
                        </>
                      )}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <div className="input-div">
              <Form.Item name="date" label="Publish date" rules={[{ required: true, message: "This field is required." }]}>
                <DatePicker style={{ width: "100%" }} showTime />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div className="input-div">
              <Form.Item name="month" label="Month" rules={[{ required: true, message: "This field is required." }]}>
                <Select size="large" placeholder="Month...">
                  {months.map((value, index) => {
                    return <Option value={value}>{value}</Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div className="input-div">
              <Form.Item name="week" label="Week" rules={[{ required: true, message: "This field is required." }]}>
                <Select size="large" placeholder="Week...">
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="author" label="Author" rules={[{ required: true, message: "This field is required." }]}>
              <Input rows={5} size="large" placeholder="Name of the author..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="link" label="Link" rules={[{ required: true, message: "This field is required." }]}>
              <Input rows={5} size="large" placeholder="Link of the paper..." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
