import {
  Modal,
} from "antd";
import axios from 'axios';
import config from "../../utils/config";
import endpoints from "../../utils/endpoints";

export default function Delete({ data, open, close }) {

  function handleSubmit() {
    axios.post(endpoints.papers.deletePaper, {
      data: { id: data.id }
    }).then((response) => {
      close();
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <Modal
      id="delete-paper"
      title={`Delete paper`}
      open={open}
      onOk={handleSubmit}
      onCancel={close}
      okText="Yes"
      cancelText="No"
    >
      <p>Are you sure you want to delete this paper?</p>
    </Modal>
  );
}
