import {
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  Form,
  notification,
  Upload,
  Steps,
  Radio,
  Select,
  Drawer
} from "antd";
import {
  LoadingOutlined,
  UploadOutlined,
  HeartOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import dayjs from 'dayjs';

import iconShares from "../../assets/images/icon-shared.svg";
import iconViews from "../../assets/images/icon-views.svg";
import config from "../../utils/config";
import localeData from 'dayjs/plugin/localeData';

const { Step } = Steps;
const { Option } = Select;
dayjs.extend(localeData)

export default function Preview({ data, open, close}) {
  return (
    <Drawer
      width={500}
      title={`Preview paper`}
      id="preview-paper"
      open={open}
      onClose={close}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <label>Preview of paper</label>
          <div className="preview-div" key={`preview`}>
            <div className="preview-text-div">
              <h2 id={`title-preview`} className='title-preview'>
                {data.title}
              </h2>
              <h3 id={`descricao-preview`} className='descricao-preview'>
                {data.description}
              </h3>
              <img src={data.img} />
              <p className="author">
                From: <a style={{ color: '#00C16E' }}>{data.author}</a>
              </p>
              <div className="views-share">
                <div className="views">
                  <img src={iconViews} />
                  0
                </div>
                <div className="share">
                  <img src={iconShares} />
                  0
                </div>
              </div>
            </div>

            <div className='preview-buttons'>
              <div className='read-article-button-div'>
                <Button color="00C16E" className='button-read'>
                  Read the article
                </Button>
              </div>
              <div className='favorite-button-div'>
                <Button color="00C16E" className='button-fav'>
                  <HeartOutlined />
                </Button>
              </div>
              <div className='share-button-div'>
                <Button color="00C16E" className='button-share'>
                  <ShareAltOutlined />
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Drawer>
  );
}
