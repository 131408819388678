import { Routes, Route, Redirect, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spin, Row, Modal, Col, ConfigProvider } from "antd";
import SignIn from "./pages/Login/Login.js";
import Main from "./layout/Main/Main.js";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/global.css";
import axios from "axios";
import Home from "./pages/Home/Home.js";
import Papers from "./pages/Papers/Papers.js";
import Categories from "./pages/Categories/Categories.js";
import Types from "./pages/Types/Types.js";
import Events from "./pages/Events/Events.js";
import Versions from "./pages/Versions/Versions.js";
import config from "./utils/config.js";
import Podcasts from "./pages/Podcasts/Podcasts.js";
import Login from "./pages/Login/Login.js";
import api from "./utils/api.js";
import endpoints from "./utils/endpoints.js";
import Loading from "./layout/Loading/Loading.js";
import Users from "./pages/Users/Users.js";

api.axiosCreate();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    if (tokenData) {
      axios
        .post(endpoints.auth.verifyToken, {
          data: tokenData,
        })
        .then(async (res) => {
          console.log(res.data);
          if (res.data.token_valid) {
            await api.axiosInit(tokenData);
            setIsLoggedIn(true);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            setIsLoggedIn(false);
            setIsLoading(false);
            navigate("/login");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          navigate("/login");
        });
    } else {
      setIsLoading(false);
      navigate("/login");
    }
  }, []);

  function handleLogout() {
    console.log("logout");
    localStorage.removeItem("token");
    window.location.reload();
  }

  function handleLogin() {
    setIsLoggedIn(true);
    navigate("/");
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00C16E",
          fontFamily: "Poppins",
        },
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          {!isLoggedIn ? (
            <Route exact path="/login" element={<Login handleLogin={handleLogin} />} />
          ) : (
            <Route element={<Main handleLogout={handleLogout} />}>
              <Route exact path="/" element={<Navigate to="/dashboard" />} replace />
              <Route exact path="/dashboard" element={<Home />} />
              <Route exact path="/papers" element={<Papers />} />
              <Route exact path="/categories" element={<Categories />} />
              <Route exact path="/types" element={<Types />} />
              <Route exact path="/events" element={<Events />} />
              <Route exact path="/versions" element={<Versions />} />
              <Route exact path="/podcasts" element={<Podcasts />} />
              <Route exact path="/users" element={<Users />} />
            </Route>
          )}
        </Routes>
      )}
    </ConfigProvider>
  );
}

export default App;
