import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Spin,
  message,
  Table,
} from "antd";
import {
  UserOutlined,
  EyeOutlined,
  HeartOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import axios from "axios";
import endpoints from "../../utils/endpoints";

const { Title } = Typography;

function Home() {

  const [dashboardData, setDashboardData] = useState({});
  const [countriesData, setCountriesData] = useState({});
  const [portugalData, setPortugalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  useEffect(() => {
    handleGetAppData();
  }, [])

  function handleGetAppData() {
    axios.get(endpoints.info.infoDashboard).then((res) => {
      console.log(res);
      let data = res.data.data[0][0];
      let countriesData = res.data.data[1];
      let portugalData = res.data.data[2][0];

      setDashboardData(data);
      setCountriesData(countriesData);
      setPortugalData(portugalData);

      setIsLoading(false)
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <>
      {isLoading ?
        <div className="d-flex jc-center ai-center w-100 h-100">
          <Spin size="large" />
        </div>
        :
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={24}>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Users'}>
                <div className="number">
                  <Row>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{dashboardData.total_users}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <UserOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Reads'}>
                <div className="number">
                  <Row>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{dashboardData.total_views}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <EyeOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Saved'}>
                <div className="number">
                  <Row>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{dashboardData.total_favoritos}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Shared'}>
                <div className="number">
                  <Row>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{dashboardData.total_shares}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <ShareAltOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="rowgap-vbox" gutter={24}>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Users'} extra={<small>PT</small>}>
                <div className="number">
                  <Row>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{portugalData.total_users}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <EyeOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Reads'} extra={<small>PT</small>}>
                <div className="number">
                  <Row>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{portugalData.total_views}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <UserOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Saved'} extra={<small>PT</small>}>
                <div className="number">
                  <Row>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{portugalData.total_favoritos}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col
              span={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Shared'} extra={<small>PT</small>}>
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <p className="f-30 bold m-0">{portugalData.total_shares}</p>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <ShareAltOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>

          <Row className="rowgap-vbox" gutter={24}>
            <Col
              span={24}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox h-full" title={'Users'} extra={<small>Country</small>}>
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={countriesData}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      }
    </>
  );
}

export default Home;
