import { Modal } from "antd";
import axios from "axios";
import config from "../../utils/config";
import endpoints from "../../utils/endpoints";

export default function Delete({ data, open, close }) {
  function handleSubmit() {
    axios
      .post(endpoints.events.delete, {
        data: { id: data.id },
      })
      .then((response) => {
        close();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Modal id="delete-category" title={`Delete event`} open={open} onOk={handleSubmit} onCancel={close} maskClosable={false} okText="Yes" cancelText="No">
      <p>Are you sure you want to delete this event?</p>
    </Modal>
  );
}
