import {
  Button,
  Input,
  Form,
  notification,
  Upload,
  Modal
} from "antd";
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useState } from "react";

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";

const { Dragger } = Upload;

export default function Create({ open, close, categories, types, events }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState('');

  const [form] = Form.useForm();

  const uploadProps = {
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      console.log(file);
      handlePreviewFile(file);
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: []
  }

  async function handlePreviewFile(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview)
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append('file', file);
      });
    } else {
      values.img = null;
    }

    formData.append('data', JSON.stringify(values));
    fetch(`${config.server_ip}${endpoints.podcasts.postPodcast}`, {
      method: "POST",
      body: formData
    }).then(res => res.json())
      .then((resData) => {
        if (resData.err) throw resData
        setTimeout(() => {
          setIsButtonLoading(false);
          form.resetFields();
          close();
        }, 1000);
      }).catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    });
  }

  function normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  function handleClose() {
    close();
    form.resetFields();
    setIsButtonLoading(false);
  }

  return (
    <Modal
      title={`Create podcast`}
      id="create-podcast"
      open={open}
      onCancel={handleClose}
      footer={[
        <Button size="large" onClick={handleClose} disabled={isButtonLoading}>
          Cancel
        </Button>,
        <Button size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
          Create
        </Button>
      ]}
    >
      <Form
        form={form}
        id="create-podcast-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Form.Item
          name="img"
          label="Imagem"
          valuePropName='fileList'
          getValueFromEvent={normFile}
        >
          <Dragger {...uploadProps} className={`dragger ${filePreview ? 'hasPreview' : ''}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <div
              className={`preview_file sponsor ${filePreview ? 'hasPreview' : ''}`}
              style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}/images/podcasts/${filePreview}`})` }}
            >
            </div>
          </Dragger>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'This field is required.' }]}
        >
          <Input type="text" placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
        >
          <Input type="text" placeholder="Descrição" />
        </Form.Item>
        <Form.Item
          name="link"
          label="Link"
          rules={[{ required: true, message: 'This field is required.' }]}
        >
          <Input type="text" placeholder="Link" />
        </Form.Item>
      </Form>
    </Modal>
  );
}