import { Row, Col, Card, Table, Button } from "antd";
import { DeleteOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import Create from "../../components/Events/Create";
import endpoints from "../../utils/endpoints";
import Update from "../../components/Events/Update";
import "./Events.css";
import Delete from "../../components/Events/Delete";

function Events() {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "5%",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "85%",
    },
    {
      title: "",
      key: "actions",
      dataIndex: "actions",
      width: "10%",
    },
  ];

  useEffect(() => {
    handleGetEvents();
  }, []);

  function handleGetEvents() {
    setIsLoading(true);
    axios
      .get(endpoints.events.getEvents)
      .then((response) => {
        let eventsData = [];
        for (let i = 0; i < response.data.length; i++) {
          let arrayData = response.data[i];
          let aux = {
            key: i + 1,
            name: arrayData.name,
            actions: (
              <>
                <div className="actions-buttons-div">
                  <Button type="primary" className="edit-action mr-10" onClick={() => handleOpenUpdate(arrayData)}>
                    <EditOutlined style={{ fontSize: "16px" }} />
                  </Button>
                  <Button danger className="edit-action" onClick={() => handleOpenDelete(arrayData)}>
                    <DeleteOutlined style={{ fontSize: "16px" }} />
                  </Button>
                </div>
              </>
            ),
          };

          eventsData.push(aux);
        }

        setData(eventsData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
    handleGetEvents();
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetEvents();
  }

  function handleOpenDelete(item) {
    setIsOpenDelete(true);
    setSelectedData(item);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetEvents();
  }

  return (
    <div className="tabled">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Events Table"
            extra={[
              <Button size="large" className="mr-10" onClick={handleGetEvents}>
                <ReloadOutlined />
              </Button>,
              <Button size="large" type="primary" onClick={handleOpenCreate}>
                Create event
              </Button>,
            ]}
          >
            <div className="table-responsive">
              <Table loading={isLoading} columns={columns} dataSource={data} className="ant-border-space" />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Events;
