import { Button, Input, Form, notification, Upload, Select, Modal } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import config from "../../utils/config";
import localeData from "dayjs/plugin/localeData";
import endpoints from "../../utils/endpoints";

const { Dragger } = Upload;
const { Option } = Select;
dayjs.extend(localeData);

export default function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  const uploadProps = {
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      console.log(file);
      handlePreviewFile(file);
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    if (Object.keys(data).length > 0 && open) {
      if (typeof data.img === "string") {
        setFilePreview(data.img);
        setFileList([
          {
            uid: "not_new",
            name: data.img,
          },
        ]);
        data.img = [{ uid: "not_new", name: data.img }];
      }
      form.setFieldsValue(data);
    }
  }, [open]);

  async function handlePreviewFile(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        if (file.uid !== "not_new") {
          formData.append("file", file);
        } else {
          values.img = file.name;
        }
      });
    } else {
      values.img = null;
    }

    formData.append("data", JSON.stringify(values));
    fetch(`${config.server_ip}${endpoints.categories.editCategory}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.err) throw resData;
        console.log(resData);
        setTimeout(() => {
          setIsButtonLoading(false);
          form.resetFields();
          close();
        }, 1000);
      })
      .catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  function handleClose() {
    close();
    form.resetFields();
    setIsButtonLoading(false);
  }

  return (
    <Modal
      title={`Update category`}
      id="update-category"
      open={open}
      onCancel={handleClose}
      maskClosable={false}
      footer={[
        <Button size="large" onClick={handleClose} disabled={isButtonLoading}>
          Cancel
        </Button>,
        <Button size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
          Update
        </Button>,
      ]}
    >
      <Form form={form} id="update-category-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile}>
          <Dragger {...uploadProps} className={`dragger ${filePreview ? "hasPreview" : ""}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <div
              className={`preview_file sponsor ${filePreview ? "hasPreview" : ""}`}
              style={{ backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}/images/categories/${filePreview}`})` }}
            ></div>
          </Dragger>
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: "This field is required." }]}>
          <Input type="text" placeholder="Name" />
        </Form.Item>
        <Form.Item name="days" label="Days">
          <Select mode="multiple" size="large" placeholder="Days...">
            <Option value={0}>Domingo</Option>
            <Option value={1}>Segunda-feira</Option>
            <Option value={2}>Terça-feira</Option>
            <Option value={3}>Quarta-feira</Option>
            <Option value={4}>Quinta-feira</Option>
            <Option value={5}>Sexta-feira</Option>
            <Option value={6}>Sábado</Option>
          </Select>
        </Form.Item>
        <Form.Item name="position" label="Position" rules={[{ required: true, message: "This field is required." }]}>
          <Input type="number" placeholder="Position" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
