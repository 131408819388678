import {
  Row,
  Col,
  Card,
  Modal,
  Table,
  Button,
  Input,
  Form,
  Spin,
  notification,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';
import "./Versions.css";
import config from "../../utils/config";
import Update from "../../components/Versions/Update";
import endpoints from "../../utils/endpoints";

export default function Versions() {

  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "VERSION NAME",
      dataIndex: "version_name",
      key: "version_name",
    },
    {
      title: "VERSION CODE",
      dataIndex: "version_code",
      key: "version_code",
    },
    {
      title: "DEVICE",
      dataIndex: "device",
      key: "device",
    },
    {
      title: "",
      key: "actions",
      dataIndex: "actions",
      width: "10%",
    },
  ];

  useEffect(() => {
    handleGetInfo();
  }, []);

  function handleGetInfo() {
    setIsLoading(true);
    axios.get(endpoints.info.appInfo).then((response) => {
      let categoriesData = [];
      for (let i = 0; i < response.data.length; i++) {
        let arrayData = response.data[i];
        let aux = {
          key: i,
          name: arrayData.name,
          version_code: arrayData.version_code,
          version_name: arrayData.version_name,
          device: arrayData.device,
          actions:
            <div className="actions-buttons-div">
              <Button type="primary" className="edit-action" onClick={() => handleOpenUpdate(arrayData)}>
                <EditOutlined style={{ fontSize: '16px' }} />
              </Button>
            </div>
        }

        categoriesData.push(aux);
      }

      setData(categoriesData);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetInfo();
  }

  return (
    <div className="tabled">
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Versions Table"
            extra={[
              <Button size="large" className="mr-10" onClick={handleGetInfo}><ReloadOutlined /></Button>
            ]}
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={data}
                loading={isLoading}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}